@import './../../../scss/variables/index.scss';

#cursor {
	z-index: 10000;
	position: absolute;
	pointer-events: none;
	mix-blend-mode: difference;
	transition: opacity ease .2s;
	// transform: translate3d(700px, 300px, 0) !important;
	
	&.hide {
		opacity: 0;
	}

	.pointer {
		color: #fff;
		pointer-events: none;
		border: solid #fff 4px;
		width: 46px;
		height: 46px;
		position: absolute;
		top: -23px;
		left: -23px;
		border-radius: 100%;
		box-sizing: border-box;
		transition: all ease .2s;
		// background: gold;
	}

	.display {

		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			background: #fff;
			border-radius: 100%;
			transform: scale(1.1);
			opacity: 0;
			transition: all ease-in-out 0.2s;
		}
	}

	.link {
		opacity: 0;
		transform: scale(1);
		width: 23px;
		border-right: none;
		border-radius: 0;
		border-top-left-radius: 27px;
		border-bottom-left-radius: 27px;

		.arrow {
			position: absolute;
			bottom: -4px;
			left: 19px;
			height: 4px;
			background: #fff;
			width: 33px;
			transition: all ease .2s;

			&:after, &:before {
				content: '';
				position: absolute;
				width: 33px;
				height: 4px;
				background: #fff;
				left: 6px;
				transform-origin: right;
				transition: transform ease 0.2s;
			}

			&:after {
				transform: translateY(0px) translateX(-1px) rotateZ(0);
			}

			&:before {
				transform: translateY(0px) translateX(-1px) rotateZ(-0);
			}
		}
	}

	.scroll {
		opacity: 0;
		height: 23px;
		width: 23px;
		left: 0px;
		border-radius: 0;
		border-top-right-radius: 27px;
		border-left: none;
		border-bottom: none;
		backface-visibility: hidden;
		transform-origin: bottom left;
		// transform: rotateZ(0deg) !important;

		.arrow {
			position: absolute;
			top: 19px;
			left: 19px;
			height: 33px;
			background: #fff;
			width: 4px;
			transition: all ease .2s;

			&:after, &:before {
					content: '';
					position: absolute;
					width: 4px;
					height: 33px;
					background: #fff;
					right: 0;
					transform-origin: bottom;
					transition: transform ease 0.2s;
				}

				&:after {
					transform: translateY(0px) translateX(1px) rotateZ(-45deg);
				}

				&:before {
					transform: translateY(0px) translateX(-1px) rotateZ(45deg);
				}
		}

	}

	&.front {
		.display {
			opacity: 0;
		}

		.scroll {
			opacity: 1;
		}
	}

	&.active {
		.display,  .scroll{
			opacity: 0;

			&:after {
				// transform: scale(1.1);
				// opacity: 1;
			}
		}

		.scroll {
			.arrow {
				&:after {
					transform: translateY(0) translateX(0) rotateZ(0);
				}

				&:before {
					transform: translateY(0) translateX(0) rotateZ(0);
				}
			}
		}

		.link {
			opacity: 1;
			transform: scale(1);

			.arrow {
				width: 39px;

				&:after {
					transform: translateY(1px) translateX(0) rotateZ(45deg);
				}

				&:before {
					transform: translateY(-1px) translateX(0) rotateZ(-45deg);
				}
			}
		}
	}
}