html, body {
	overflow: hidden;
	position: fixed;
	height: 100%;
	width: 100%;
}

body {
  margin: 0;
  font-family: $fontMain;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background: #fff;

	::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 15px;
		background-color: #e8e8e8;

		@include breakpoint(xlarge) {
			display: none;			
		}
	}

	::-webkit-scrollbar-thumb {
		border-radius: 0px;
		background-color: #c1c1c1;

		@include breakpoint(xlarge) {
			display: none;			
		}
	}
}

body, html, * {
	box-sizing: border-box;
}