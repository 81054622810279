@import './../../../scss/general/breakpoints.scss';

.frontpage-slide.lasso {
	.text {
		position: absolute;
		width: calc(50vw - 90px);
		top: 30px;
		right: 90px;
		font-size: 15px;
		mix-blend-mode: difference;
		color: #fff;
		z-index: 1000;

		@include breakpoint(desktopLarge) {
			font-size: 22px;
		}

		@include breakpoint(mobileXLarge) {
			display: none;
		}
	}

	#lasso-canvas {
	  width: 100%;
	  height: 100%;
	  position: absolute;
	  top: 0;
	  left: 0;
	}
}
