@import './breakpoints.scss';
@import './../variables';

a {
	text-decoration: none;
	color: #000;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-family: $fontLight;
  line-height: 1;
}

h1 {
	font-size: 52px;

	@include breakpoint(large) {
		font-size: 40px;
	}

	@include breakpoint(mobile) {
		font-size: 8vw;
	}
}

p {
	font-size: 18px;
	line-height: 1.8;
	letter-spacing: 0.3px;
	margin-bottom: 45px;
	margin-top: 0;
	width: 100%;

	&:first-of-type {
		margin-top: -6px;

		@include breakpoint(small) {
			margin-top: 0;
		}
	}

	@include breakpoint(small) {
			margin-bottom: $pageSectionMarginBottomMobile;
		}

	a {
		text-decoration: underline;
	}
}